import * as React from "react"
import { Helmet } from "react-helmet";

const IndexPage = () => {
  return (
    <main>
      <Helmet>
        <title>IRONMAN 70.3 Augusta Race Report</title>
        <meta
          name="msapplication-config"
          content="/assets/xml/browserconfig.xml"
        />
        <meta name="theme-color" content="#a6192d" />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />

        <meta itemprop="name" content="IRONMAN 70.3 Augusta Race Report" />
        <meta property="og:title" content="IRONMAN 70.3 Augusta Race Report" />
        <meta
          itemprop="image"
          content="https://colinlord.com/images/augusta703-race-report/meta.jpg"
        />
        <meta
          property="og:image"
          content="https://colinlord.com/images/augusta703-race-report/meta.jpg"
        />
        <meta name="twitter:card" content="summary_large_image" />

        <link
          href="https://fonts.googleapis.com/css?family=Quattrocento+Sans:400,700"
          rel="stylesheet"
        />

        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.1/dist/css/bootstrap.min.css"
          integrity="sha384-F3w7mX95PdgyTmZZMECAngseQB83DfGTowi0iMjiWaeVhAn4FJkqJByhZMI3AhiU"
          crossorigin="anonymous"
        />

        <link href="/race-report.css" rel="stylesheet" />

        <script
          src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.1/dist/js/bootstrap.min.js"
          integrity="sha384-skAcpIdS7UcVUC05LJ9Dxay8AXcDYfBJqt1CJ85S/CFujBsIzCIv+l9liuYLaMQ/"
          crossorigin="anonymous"
        ></script>

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
      </Helmet>

      <div className="container-fluid" style={{ overflow: "hidden" }}>
        <div className="row" style={{ margin: "auto -30px" }}>
          <div className="col-12">
            <img
              className="img-fluid"
              src="/images/augusta703-race-report/header.jpg"
              alt=""
            />
          </div>
        </div>
      </div>

      <div className="container">
        <div className="px-4 py-4 text-center border-bottom">
          <h1 className="display-4 fw-bold">
            IRONMAN 70.3 Augusta Race Report
          </h1>
          <div className="col-lg-6 mx-auto">
            <p className="lead mb-0">September 25, 2022</p>
            <p className="lead mb-0">Augusta, GA</p>
          </div>
        </div>
        <div className="px-4 py-4 border-bottom">
          <h1>Background</h1>
          <p>
            The last time I wrote a race report was back in June. I’d just
            completed a five-week stretch that included two IRONMAN 70.3s in
            Panama City and Chattanooga, then followed it up with an Olympic
            distance tri here in Nashville. I’d intentionally left July and
            August open because I wanted to put all my focus on Augusta. I was
            thrilled with how the summer was going and had high hopes for what
            awaited in this race.
          </p>

          <p>
            But I got COVID in mid-June. And then, six weeks later, at the end
            of July, I got COVID again.
          </p>

          <p>
            It sucked. The scariest moment was after testing positive a second
            time. My doctor told me I should be worried about pneumonia, since
            the two infections were so close together. He said this was serious
            and I should have a bag packed to be ready to go to the ER at a
            moment’s notice if my symptoms got worse.
          </p>

          <p class="pb-4">Gulp.</p>

          <div className="full-image">
            <figure className="figure">
              <img src="/images/augusta703-race-report/covid.jpg" alt="" />
            </figure>
            <figcaption className="figure-caption">
              I like to believe everything on the counter played a role in
              getting me through COVID.
            </figcaption>
          </div>

          <p>
            In the grand scheme of things, I certainly could have had it worse.
            I never went to the hospital, and I’ve mostly recovered. But I’ve
            battled brain fog on and off since July. It has only been in the
            last couple of weeks that my heart rate has returned to normal. As
            it relates to triathlon training, I went from hoping to finally
            cross off a sub-6 hour 70.3 to wondering if I would even be able to
            race Augusta at all.
          </p>

          <p>
            Letting the remainder of the season turn into a pity party was
            tempting. I had gotten vaccinated and boosted, but I still caught it
            twice. Both rounds of COVID came from work-related exposures where I
            realistically couldn’t have avoided them. If I’d gotten it at a
            sold-out Braves game, at least it would have happened from something
            fun.
          </p>

          <p>
            On top of that, my grandma died while I was positive and quarantined
            with round two. There was unfortunate and unnecessary family drama
            that followed in the days afterward.
          </p>

          <p>
            I don’t want to dwell on any of this other than to reiterate what I
            kept telling myself as the weekend approached: Whatever happens on
            race day, I should celebrate that I’m even showing up.
          </p>
        </div>
        <div className="px-4 py-4 border-bottom">
          <h1>Pre-Race</h1>
          <p>
            Katy has always been my sherpa for IRONMAN races. It’s a real luxury
            not to worry about morning parking and to have her support out there
            during the bike and run. However, a few hours before we were
            supposed to head for Augusta, she realized she needed to stay behind
            in Nashville.
          </p>

          <p>
            Her bags were already in my car. Pulling them back out was a real
            bummer. Normally when I leave for races, I am really hyped and
            excited. I didn’t feel that way for the first hour or so of the
            drive. But I tried to remind myself that I wouldn’t be solo in
            Augusta. I knew four other people who would be there. That doesn’t
            make up for suddenly being without Katy, but I was in search of
            positives wherever I could find them!
          </p>
          <p class="pb-4">
            The drive from Nashville to Augusta takes me through Atlanta, which
            greeted me with several areas of gridlock. By the time I got to the
            Airbnb that I was sharing with Tiffany and Caitlin, aka{" "}
            <a href="https://instagram.com/teamsaunderstri" target="_blank" rel="noreferrer">
              Team Saunders
            </a>
            , it was a little after midnight.
          </p>

          <div className="full-image">
            <figure className="figure">
              <img
                src="/images/augusta703-race-report/atl-traffic.jpg"
                alt=""
              />
            </figure>
          </div>

          <p>
            Saturday was uneventful. I’d never been to Augusta before, much less
            even seen the bike or run course. I joined Tiffany and Caitlin on a
            drive of the bike course and proceeded to head to registration. I
            ran into Brian and his family at the Augusta Convention Center,
            which was nice. I went back to the house to grab my bike and take it
            to transition. For all the craziness that led into this race, I was
            relieved today was calm and easy going.
          </p>

          <p class="pb-4">
            The Saunders and I went to a pasta dinner at St. Paul’s Church near
            the start line. We brought back a monumental amount of carbs to our
            AirBnb and watched college football before heading to bed around 8
            p.m.
          </p>

          <div className="full-image">
            <figure className="figure">
              <img src="/images/augusta703-race-report/pasta.jpg" alt="" />
            </figure>
          </div>
        </div>
        <div className="px-4 py-4 border-bottom">
          <h1>Race Morning</h1>
          <p>
            I tend not to sleep well the night before races because my mind is
            in overdrive thinking about tomorrow. But, I actually slept well
            this time.
          </p>
          <p>
            I think it’s because I’d come to peace with what this race was and
            wasn’t.
          </p>
          <p>
            I likely wasn’t going to be proud of my times (spoiler alert: I
            wasn’t), but I mentally did a good job of keeping everything in
            perspective. As an example, without Katy to drop me off, I had to
            find parking - always a stressful task on race morning! As I hunted
            for a parking spot, I tried to focus on how grateful I was to be
            even at the race. Just six weeks ago, I was in COVID quarantine, and
            now I’m at an IRONMAN 70.3.
          </p>
          <p>
            I had a few moments throughout the weekend where I briefly cracked,
            but I put a lot of effort into keeping those negative thoughts at
            bay.
          </p>

          <p>
            That might sound forced – maybe it is. But I genuinely was happy and
            thankful to be there. I wanted to race with a very specific mindset:
            full of gratitude.
          </p>

          <p class="pb-4">
            I got my gear set up and then waited with Tif and Cait until it was
            time to seed ourselves for the swim. Despite the crowds, Nate
            somehow saw me and came up to say hello. I forgot about any
            lingering pre-race jitters while we talked about Apple Watches and
            the new iOS release that had come out last week. 😂
          </p>

          <div className="full-image">
            <figure className="figure">
              <img src="/images/augusta703-race-report/pre-race.jpg" alt="" />
            </figure>
            <figcaption className="figure-caption">
              I need to up my jacket game compared to these two.
            </figcaption>
          </div>
        </div>

        <div className="px-4 py-4 border-bottom">
          <h1>Swim</h1>
          <p>
            <strong>Time:</strong> 33:51
            <br />
            <strong>Distance:</strong> 1.2 miles
            <br />
            <strong>Pace:</strong> 1:37/100 yd
            <br />
            <strong>AG:</strong> 130/179
            <br />
            <strong>Men:</strong> 804/1216
            <br />
            <strong>Overall:</strong> 1133/1986
            <br />
          </p>
          <p>
            <strong>Weather:</strong> Scattered clouds
            <br />
            <strong>Water Temperature:</strong> 75°F
            <br />
            <strong>Current:</strong> Oh yeeeeeah
            <br />
            <strong>Suit:</strong> Full-sleeved wetsuit
          </p>
          <p class="pb-4">Game time.</p>
          <div className="full-image">
            <figure className="figure">
              <img src="/images/augusta703-race-report/pre-swim.jpg" alt="" />
            </figure>
          </div>
          <p>
            I got goosebumps jumping into the river, and it wasn’t from the cool
            water.
          </p>
          <p class="pb-4">
            So much shit went wrong this summer. But here I am, swimming down
            the Savannah River. How awesome is this?
          </p>
          <div className="full-image">
            <figure className="figure">
              <img src="/images/augusta703-race-report/swim-start.jpg" alt="" />
            </figure>
          </div>
          <p>
            In hindsight, I probably didn’t focus on my form or sighting as much
            as I should have. I spent all 33 minutes (!!!) just basking in being
            out in a river, watching the sun rise up over the bridges, and
            thinking how far this was from things like the taste of Paxlovid or
            COVID tests up my nose.
          </p>

          <p>ALSO! MY TIME! 33 MINUTES!!!</p>

          <p class="pb-4">
            Augusta’s downstream swim held true to its reputation. I set a PR
            for a swim in a 70.3!
          </p>

          <div className="full-image">
            <figure className="figure">
              <img
                src="/images/augusta703-race-report/swim-finish.jpg"
                alt=""
              />
            </figure>
          </div>
          <div className="clearfix"></div>
        </div>

        <div className="px-4 py-4 border-bottom">
          <h1>Transition 1: Swim to Bike</h1>
          <p>
            <strong>Time:</strong> 6:31
            <br />
            <strong>AG:</strong> 119/179
          </p>
          <p>
            Not to make this sound too sappy, but I thought about my grandma
            when I came out of the river and headed to transition.
          </p>
          <p>
            Whenever my grandparents visited when I was a kid, she always said
            the same thing before leaving to go back to Miami. She had a third
            eye that would watch out for me after she left.
          </p>

          <p>
            I hope she had her third eye in Augusta today to see this incredible
            thing about 2000 of us were doing.
          </p>

          <p>Let's ride.</p>
          <div className="clearfix"></div>
        </div>

        <div className="px-4 py-4 border-bottom">
          <h1>Bike</h1>
          <p>
            <strong>Time:</strong> 3:10:02
            <br />
            <strong>Distance:</strong> 56 miles
            <br />
            <strong>Pace:</strong> 17.6 mph
            <br />
            <strong>AG:</strong> 124/179
            <br />
            <strong>Men:</strong> 800/1216
            <br />
            <strong>Overall:</strong> 1071/1986
            <br />
          </p>
          <p>
            <strong>Weather:</strong> Partly Cloudy, in the 70s, and a light
            breeze
            <br />
            <strong>Road Conditions:</strong> Less than ideal. Lots of
            construction and potholes.
            <br />
            <strong>Terrain:</strong> Scattered false flats mixed with six
            bigger climbs
          </p>

          <p>
            I am so dang glad I scouted the course with Tif and Cait the day
            before. My Wahoo bike computer will tell me about all the turns and
            elevation changes. But having seen the course gave me a good bit of
            ease.
          </p>

          <p>
            The first 10 miles and the last 10 miles are pretty flat. In
            between, there are six climbs. That’s how I decided to divide the
            race up in my head. I didn’t count miles. I counted climbs – six
            climbs to go, five to go, etc.
          </p>

          <p class="pb-4">
            I felt remarkably great for the first 20 miles or so, but I knew at
            some point I was going to start to tire. I hadn’t biked longer than
            30 miles since all this COVID nonsense.
          </p>

          <div className="full-image">
            <figure className="figure">
              <img src="/images/augusta703-race-report/bike.jpg" alt="" />
            </figure>
          </div>

          <p>
            Ironically enough, the hardest part of the ride was the final 10
            flat miles. It was an easy part of the course, but my stamina just
            wasn’t up for this kind of ride. It left me slightly concerned about
            the half-marathon run that loomed next.
          </p>

          <p>But all things considered, I’m pretty happy with how this went.</p>

          <div className="clearfix"></div>
        </div>

        <div className="px-4 py-4 border-bottom">
          <h1>Transition 2: Bike to Run</h1>
          <p>
            <strong>Time:</strong> 6:40
            <br />
            <strong>AG:</strong> 144/179
          </p>
          <p>
            At some point, I need to get a bit quicker at my transition times.
            However, after the experience of somebody crashing into me at the
            dismount line at Gulf Coast, I’m happy to say T2 in Augusta was
            wildly uneventful.
          </p>
          <div className="clearfix"></div>
        </div>

        <div className="px-4 py-4 border-bottom">
          <h1>Run</h1>
          <p>
            <strong>Time:</strong> 2:33:59 (minus the train), 2:46:07 (official
            time)
            <br />
            <strong>Distance:</strong> 13.1 miles
            <br />
            <strong>Pace:</strong> 11:48 / mile
            <br />
            <strong>AG:</strong> 144/179
            <br />
            <strong>Men:</strong> 873/1216
            <br />
            <strong>Overall:</strong> 1212/1986
            <br />
          </p>
          <p>
            <strong>Weather:</strong> Mostly Sunny, temps warming into mid-80s
            <br />
            <strong>Road Conditions:</strong> Good except for a rogue train that
            crashed the party
            <br />
            <strong>Terrain:</strong> Flat
          </p>
          <p>
            Ho boy, these first couple of miles sucked. It’s always a bit of a
            challenge for the legs to switch from biking to running, but this
            was much harder than usual. With my lack of recent fitness and
            conditioning, I started to get worried that I had a 13.1-mile death
            march in front of me.
          </p>

          <p>
            But thankfully, I began to pull it together around mile three. I
            didn’t feel great, but I’d gotten into a 10:30-ish pace. Given the
            circumstances, I was thrilled by this.
          </p>

          <p>
            I missed Katy. In my other half and full IRONMAN events, I always
            knew she was somewhere out on the run course or at an aid station.
            Seeing her always gives me a boost. When I raced Gulf Coast, I saw
            her three times, and my mile splits after each are noticeable on
            Strava.
          </p>

          <p>Thankfully, I wasn’t totally alone out there.</p>

          <p class="pb-4">
            One cruel thing about the run course is that early on, you run next
            to the finish line long before getting to finish yourself. But as an
            upside, I got to see Brian finish an incredible day with a time of
            4:34!
          </p>

          <div className="full-image">
            <figure className="figure">
              <img src="/images/augusta703-race-report/run.jpg" alt="" />
            </figure>
          </div>

          <p>
            Around mile six, a train arrived in downtown Augusta. Not only did
            it block the run course where I was, but it also blocked athletes in
            two other places. According to my watch, I ended up standing there
            for a little over 12 minutes. Crazy stuff.
          </p>
          <p class="pb-4">
            I’m grateful I wasn’t going for a specific time (or maybe
            someday…chasing a world’s slot.) I would have been really upset. I
            sympathize with anyone who lost out on a goal time, a world’s slot,
            or a podium slot because of the train.
          </p>

          <div className="full-image">
            <figure className="figure">
              <img src="/images/augusta703-race-report/train.jpg" alt="" />
            </figure>
          </div>

          <p>
            I had seen Tiffany earlier on the run and knew she was right behind
            me. With nothing else to do, I ran backwards towards her to tell her
            about the train up ahead. I have never run backward in a race
            before!
          </p>
          <p>
            Eventually, the train moved on, leaving me with seven miles to go.
            Much like on the bike portion, my lack of recent training because of
            COVID began to show. Since June, the farthest I’d run had been only
            10 miles. The final three miles of the half marathon were a SLOG.
          </p>
          <p class="pb-4">
            I tried not to focus on what hurt but instead appreciate the
            lingering final minutes of my 2022 race season. My performance was
            forgettable, but the day was not. I’m really proud of what happened
            here today.
          </p>

          <div className="full-image">
            <figure className="figure">
              <img src="/images/augusta703-race-report/run-finish.jpg" alt="" />
            </figure>
          </div>

          <div className="clearfix"></div>
        </div>

        <div className="px-4 py-4 border-bottom">
          <h1>Postrace</h1>
          <p>
            <strong>Overall Time:</strong> 6:32:12 (minus the train), 6:43:12
            (official)
            <br />
            <strong>AG:</strong> 144/179
            <br />
            <strong>Men:</strong> 872/1216
            <br />
            <strong>Overall:</strong> 1212/1986
            <br />
          </p>
          <p>
            Caitlin was waiting at the finish line for Tiffany and me. She took
            my chip for me and pointed me in the direction of pizza and Coke. I
            scarfed down some carbs, sent Katy a text that I was done, and
            Tiffany crossed the finish line shortly afterward.
          </p>

          <div className="full-image">
            <figure className="figure">
              <img
                src="/images/augusta703-race-report/finish-line.jpg"
                alt=""
              />
            </figure>
            <figcaption className="figure-caption">
              Astros fans and Braves fan act like they are friends.
            </figcaption>
          </div>

          <p>
            I looked up my times and found myself rather unhappy for a few
            minutes, but I wanted to push those negative thoughts away.
          </p>

          <p class="pb-4">
            I tried to remind myself that I battled through so much shit. The
            takeaway shouldn’t be that I faded on the end of the bike and run.
            It should be that I went from a double dip of COVID to the finish
            line of an IRONMAN 70.3 in just six weeks.
          </p>

          <div className="full-image">
            <figure className="figure">
              <img
                src="/images/augusta703-race-report/battle-medal.jpg"
                alt=""
              />
            </figure>
          </div>

          <p class="pb-4">
            The #BattleATL koozie seemed more than appropriate.
          </p>

          <div className="full-image">
            <figure className="figure">
              <img
                src="/images/augusta703-race-report/post-race-meal.jpg"
                alt=""
              />
            </figure>
          </div>

          <div className="clearfix"></div>
        </div>

        <div className="px-4 py-4 border-bottom">
          <h1>Next Steps</h1>
          <p>
            My first reaction after finishing was that this was the forgettable
            race in a wonderful, but weird, 2022. This season included the ATL
            Marathon (in a monsoon), three IRONMAN 70.3s, and a handful of other
            regional events around the Nashville area.
          </p>

          <p>
            As I write this, I’m now a bit kinder towards how Augusta went. This
            race made me realize how much I like this sport. Three seasons down,
            and I enjoy it more now than I ever have. When I finished my first
            full in Chattanooga a year ago, I relished the idea of taking a
            couple of months off from anything resembling structured training. I
            certainly didn’t want to quit, but I couldn’t wait for a break.
          </p>

          <p>
            Before the race day in Augusta ended, the three of us were already
            talking about IRONMAN 70.3 Chattanooga next May. During the drive
            home, I thought about that race quite a bit, particularly because I
            drove through Chatt on the way back home to Nashville.
          </p>

          <p>
            I plan to take a few easy weeks during October, especially with Katy
            and I heading to the beach to celebrate our 10-year anniversary.
            (I’m not sure I know what a vacation without a triathlon even looks
            like anymore.) But when we get home, I can’t wait to get back to
            work. My first sub-6 triathlon is still out there. Hopefully, it’s
            waiting for me in Chattanooga next spring.
          </p>

          <p>Go fuck yourself, COVID. I finished the race anyway.</p>

          <div className="clearfix"></div>
        </div>

        <div className="px-4 py-4">
          <p>
            (A couple of the pictures above are swiped from Cait and Tif's
            YouTube channel where they documented their race days in Augusta. If you
            want to hear how their days went...or just like awesome drone
            footage...check out their videos <a href="https://www.youtube.com/watch?v=NhrsrTqDepw" target="_blank" rel="noreferrer">here</a> and <a href="https://www.youtube.com/watch?v=gKWZBAlkJeQ" target="_blank" rel="noreferrer">here</a>. There are a handful of Colin cameos in both!)
          </p>
        </div>
      </div>
    </main>
  );
}

export default IndexPage
